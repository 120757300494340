// Do not explicitly type this, the english translations are used as our master translation and as our fallback language.
// When we let TypeScript infer the object and its keys we can type other languages strictly based on this.

import moment from 'moment'
import { reduce } from 'ramda'
import {
  AllowedReservationTimes,
  CurrencyCode,
  Weekday,
  PublishingStatus,
  TieredUnit,
  ReservedSlot,
  SpacentSellableType,
  SpaceType,
} from '../../types'
import { rounded } from '../../utils/format'
import { generateTimeFormatters } from './generators'
import { HeardFromSource } from '../../constants'
import { enCountryCodeTranslations } from './country_code_translations'

export const timeFormats = {
  momentWebDayFormat: 'MMM Do YYYY',
  momentMonthFormat: 'MMM YYYY',
  momentDateFormat: 'D MMM YYYY',
  momentDateTimeFormat: 'MM/DD/YYYY hh:mm A',
  momentTimeFullFormat: 'h:mm A',
  momentTimeHourFormat: 'h A',
  weekOrder: [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ] as Weekday[],
  weekDayAbbreviations: {
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat',
    sunday: 'Sun',
  },
}

export const openTimesForDays = (
  reservationTimes: AllowedReservationTimes,
  localeTimeFormats: typeof timeFormats,
) => {
  const openDays = reduce<Weekday, Weekday[]>(
    (acc, curr): Weekday[] => [
      ...acc,
      ...(reservationTimes[curr].length > 0 ? [curr] : []),
    ],
    [],
    localeTimeFormats.weekOrder,
  )

  const formatHour = (weekday: Weekday, hoursIndex: number) =>
    moment(reservationTimes[weekday][hoursIndex], 'H:mm').format(
      localeTimeFormats.momentTimeFullFormat,
    )

  const formatOpeningHourForDay = (wd: Weekday) =>
    `${localeTimeFormats.weekDayAbbreviations[wd]}: ${formatHour(
      wd,
      0,
    )} - ${formatHour(wd, 1)}`

  return openDays.map(formatOpeningHourForDay)
}

const spacentSellableNames: Record<SpacentSellableType, string> = {
  [SpacentSellableType.consumerCoworkingPass]: 'individual coworking pass',
  [SpacentSellableType.coworkingPass5persons]:
    'Business monthly pass for 1-5 users',
  [SpacentSellableType.coworkingPass10persons]:
    'Business monhtly pass for 6-10 users',
  [SpacentSellableType.subscriptionTest]:
    'test one person membership for organization',
  [SpacentSellableType.volumeBasedReservations]:
    'volume-based reservations - for the whole organization',
}

export const constantStrings = {
  ...spacentSellableNames,

  // Languages
  en: 'English',
  fi: 'Finnish',
  zh: 'Chinese',
  sv: 'Swedish',

  // general
  collapse: 'Collapse',
  language: 'Language',
  ok: 'OK',
  close: 'Close',
  reserve: 'Reserve',
  cancel: 'Cancel',
  delete: 'Delete',
  cancelReservation: 'Cancel reservation',
  cancelReservations: 'Cancel selected reservations',
  makeANewReservation: 'Make a new reservation',
  continue: 'Continue',
  and: 'and',
  unknownError:
    'Unknown error occurred, please try again.\nIf this error persists, please contact hello@spacent.com',
  hide: 'Hide',
  blockReservations: 'Block reservations',
  forceHide: 'Block new but keep existing reservations',
  notifyOverlappingReservees: `Enter a message to customers whose reservations overlap the time you are making unavailable.\n\nSpecify some way to contact the sender.\n\nIf they may still use the spaces normally at the intended times, you may avoid sending any message by leaving this empty.`,
  select: 'Select...',
  other: 'Other...',
  noOptions: 'No options',
  closeWithoutSaving: 'Close without saving?',
  yes: 'Yes',
  no: 'No',
  save: 'Save',
  saveInformation: 'Save information',
  search: 'Search...',
  clear: 'Clear',
  copy: 'Copy',
  copied: 'Copied!',
  resetToDefaults: 'Reset to defaults',
  defaultConfirmModalTitle: 'Before you continue',
  generalErrorTitle: 'Oh no',
  generalErrorMessage: 'Something went wrong, please try again later.',
  giveFeedback: 'Give feedback',
  noFeedback: 'No feedback given yet',
  givenRating: 'Given rating',
  nameless: 'Unnamed',

  // Surveys
  heardFromSourceQuestionnaireTitle: 'Where did you find out about us?',
  heardFromSourceQuestionnaireDescription: '',

  // dates
  yesterday: 'Yesterday',
  today: 'Today',
  tomorrow: 'Tomorrow',
  reservationOnDate: 'You have a reservation on this date for',

  // nav
  reservations: 'Reservations',
  spaces: 'Spaces',
  logOut: 'Log out',
  // nav -- admin
  myOrganization: 'My Organization',
  locations: 'Locations',
  usermanagement: 'Users',
  usergroups: 'Usergroups',
  people: 'People',
  managedUsergroups: 'Managed usergroups',
  personalUsergroups: 'Personal usergroups',
  settings: 'Settings',
  basicInformation: 'Basic information',
  billing: 'Billing',
  servicePlanAndUse: 'Service Plan & Use',
  appHomeView: 'App home view',

  // service plan management
  memberships: 'Memberships',
  members: 'Members',
  startingDate: 'Starting',
  adjustNumberOfMemberships: 'Adjust number of memberships',
  toChangeServicePlanContactUsAt: 'To change service plan, contact us at',
  utilizationDuringCurrentPeriod: 'Utilization this month',
  notCurrentlyInUse: 'Not currently in use.',
  accesses: 'Accesses',
  total: 'Total',
  recurringTotal: 'Recurring total',
  noAccessesToBePaidThisMonth:
    'No accesses to be paid in addition to memberships this month.',
  totalServiceCost: 'Currently accumulated cost for next invoice',
  noCostsYet: 'No costs accumulated yet.',
  invoicingByHighestNumberOfMemberships:
    'Each full month period is invoiced according to the highest number of memberships in effect during that month.',
  noChangeAgainToday:
    'Number of memberships has changed today. Next change can be scheduled to start at a later date.',
  discount: 'discount',

  // payment methods
  cash: 'Pay by cash',
  invoice: 'Invoice',
  hostInvoice: 'Invoice from the host',
  subscription: 'Subscription',
  creditCard: 'Credit card',

  // login
  yourWorkEmail: 'Your work email',
  welcomeTitle: 'Welcome to Spacent!',
  welcomeMessage:
    'Book a work station or a meeting room from your own office by logging in here.',
  welcomeMessageContinued:
    'Please use the Spacent app for booking coworking locations.',
  loginFailed: 'Login failed.',
  getStartedCTA: 'Log in to your own office',
  returnToLogin: 'Return to login page',
  organizationNotFound:
    'Seems that you do not yet have customership.\nPlease contact hello@spacent.com',
  emailTokenVerificationFailed:
    'Email verification failed.\nPlease try again. If this error persists, please contact hello@spacent.com',
  userDataFetchingFailed:
    'Failed to fetch required data.\nIf this error persists, please contact hello@spacent.com',
  startBySigningIn: 'Start by signing in.',
  beforeWeStart: 'Before we start.',
  pleaseFillNameAndPhone:
    'Please fill in your full name and phone number. This information is needed for contact with lobby receptionist.',
  pleaseAcceptTermsAndConditions: 'Please read and accept terms and conditions',
  yourName: 'Your full name',
  yourPhone: 'Your phone number',
  agreeAndContinue: 'Agree and continue',
  bySelectingAgree: 'By selecting Agree and continue below, I agree to Spacent',
  termsAndConditions: 'Terms and Conditions',
  privacyPolicy: 'Privacy Policy',
  invalidAccessToken: 'Invalid access token.',
  invalidEmail: 'Please recheck the email address.',

  // reserving
  'reservationTimespan:pre': 'Reserve beginning',
  'reservationTimespan:mid': 'until',
  reservationDays: 'days',
  reservationHours: 'hours',
  reservationId: 'Reservation id:',
  tooLongReservationPeriod:
    'You can reserve this room for a period of {amount} {units} at most.',
  changedReservationTime:
    'Your reservation time was changed due to overlapping times.',

  // reserving -- admin
  tooManyReservationsForCapacity:
    'Too many simultaneous reservations for room capacity!',

  // reservation confirmation
  confirmReservation: 'Reserve space',
  confirmReservationAddress: 'Address',
  confirmReservationTimespan: 'Reservation timespan',
  preReservationChecks: 'Pre Reservation Checks',
  showPreReservationChecks: 'Show checks before reservation',
  alreadyReservedThis:
    'You already have reserved this space for the selected time',
  alreadyReservedSpace:
    'You already have a reservation during the selected time for this space:',

  // reservations -- admin
  bookingCalendar: 'Booking Calendar',
  calendar: 'Calendar',
  list: 'List',
  calendarIntegrationError:
    'We were unable to fetch data from the calendar system your of your organization and there may be events missing here.',
  noPaymentMethodsError:
    'Unable to create event: selected space does not have any payment methods enabled',
  calendarNoSpacesBeginning: 'It seems you dont have any spaces yet.',
  calendarNoSpacesLink: 'You could create some?',
  view: 'View',
  searchResults: 'Reservations',
  searchFilter: 'Filters',
  start: 'Start',
  end: 'End',
  visitor: 'Visitor',
  specificSpaces: 'Specific spaces',
  cancellationDialogLabel:
    'If you want, you can add a custom message to the cancellation announcement for the guest.',
  forExample: 'For example:',
  cancellationMessageExample: `
    Please accept our apology for having to cancel your workspace reservation
    due to unexpected circumstances.
    We welcome you to book another space or time instead.
  `,

  // reservations dialog -- admin
  createReservation: 'Create Reservation',
  viewReservation: 'View Reservation',
  reservationWhere: 'Where',
  reservationStart: 'Start',
  reservationEnd: 'End',
  reservedFor: 'Reserved for',
  reserveFor: 'Book for',
  organization: 'Organization',
  paymentMethod: 'Payment method',
  invoiceReference: 'Invoice reference',
  yourReference: 'Your reference',
  reservationNotes: 'Notes',
  cateringInformation: 'Catering Information',
  addCateringItem: 'Add item',
  reservationRoomPrice: 'Room price',
  reservationTotalPrice: 'Total price',
  updateReservation: 'Update reservation',
  myOrganizationInvoiceDetailsMissing:
    'Organization invoicing details are missing. Please add them under the My organization tab or contact us at support@spacent.com',
  reservationNotFoundTitle: "We couldn't find the reservation!",
  reservationNotFoundMessage:
    'If you think it should be found, you can contact our support.',
  reservationCreated: 'Reservation created.',
  reservationUpdated: 'Reservation updated.',
  hostPayment: 'Payment',
  hostPaymentTooltip:
    'This is the payment for the host that takes the possible commission and taxes into consideration',
  referToMaterial: 'Refer to material',
  modalReservationCancelledTitle: 'Reservation cancelled',
  modalReservationCancelledDescription:
    'This reservation has been cancelled and is no longer visible.',

  // spaces
  noAvailableSpaces:
    'Unfortunately, there are no available spaces matching your criteria.',
  cancelReservationBeforeChangingVisibility:
    'You need to cancel future reservations before changing the spaces visibility settings.',
  includedInMeetingRoomPackage: 'Included in meeting room package',
  getQrCode: 'Show QR Code',
  qrCodeBlockTitle: 'Quick reservation QR-code',
  qrCodeBlockDescription:
    'You can use this QR code to provide a faster way for users to make reservations in this space',

  // reservations
  noReservations: "You don't have any active reservations.",

  // my organization -- admin
  nameAndBusinessId: 'Name & Business ID',
  organizationName: 'Organization name',
  businessIdLabel: 'Business ID / VAT ID',
  authentication: 'Authentication',
  authenticationType: 'Authentication type',
  authConfigured:
    'The organization authentication type has been configured by Spacent team. To change the type, please contact us at',
  creditCardPayment: 'Credit card payment',
  pdfInvoice: 'Send me a PDF invoice to my email',
  pdfInvoiceReceiver: 'Email address',
  useInvoicingOperator: 'Use an invoicing operator',
  eInvoiceOperator: 'E-invoicing operator',
  eInvoiceAddress: 'E-invoicing address',
  invoiceContact: 'Contact email address',
  changeBillingMethod:
    "If you wish to cancel your organization's Spacent membership, please contact",
  cardholderName: 'Cardholder name',
  cardholderEmail: 'Billing email',
  cardNumber: 'Card number',
  cardExpiration: 'Card expiration',
  cardCvc: 'Card CVC',
  creditCardDisclaimer:
    'Our card transactions are processed securely by Stripe. We accept the major credit cards.',
  paymentInfoUpdated: 'Payment information updated successfully.',
  billingMethod: 'Billing method',
  updateCard: 'Update card',
  deleteCard: 'Remove card',
  authorizePendingCardPayment: 'Authorize pending credit card payment',
  pendingAmount: 'Pending amount',
  paymentCard: 'Payment card',
  cardEndingIn: 'ending in',
  paymentAuthorizationSuccess: 'Payment authorized successfully',
  paymentAuthorizationFail: 'Payment authorization failed',

  // custom network
  locationNotAvailableForOrganization:
    'This location is not available for your organization.',
  spaceNotAvailableRoomListTag: 'Not available',
  customNetwork: 'Subscription & pay-per-use',
  customNetworkSaved: 'Your custom network is now saved. 🎉',
  customNetworkSavedError:
    'Oh oh 😭. There was an error saving your custom network!',
  customNetworkCountriesFailed:
    'Oh oh 😭. There was an error retrieving available countries!',
  customNetworkFetchFailed:
    'Oh oh 😭. There was an error retrieving your custom network!',
  customNetworkCountries: 'Countries in your custom network',
  customNetworkCountriesExclude: 'All countries except the ones selected below',
  customNetworkCountriesIncludeOnly: 'Only the countries selected below',
  createCustomNetwork: 'Create custom network',
  editCustomNetwork: 'Edit custom network',
  editCustomNetworkShort: 'Edit',
  deleteCustomNetwork: 'Delete',
  deleteCustomNetworkConfirmation:
    'Are you sure you want to delete the custom network?',
  customNetworkDeleted: 'Your custom network is now deleted.',
  customNetworkDeletedError:
    'Oh oh 😭. There was an error deleting your custom network!',
  customNetworkInitialInstructions:
    'Create a custom network of countries where your employees are allowed to book spaces.',
  customNetworkCountriesInstructions:
    'You have set up a custom network based on countries. Only the countries below are available through Spacent.',
  noProductsActiveInRegions: 'No products currently active in regions.',
  allowed: 'allowed',
  subscriptionOverview: 'Subscription overview',
  productsPerRegion: 'Products per region',

  // locations -- admin
  allLocations: 'All locations',
  announcements: 'Announcements',
  buildingManagement: 'Building management',
  feedback: 'Ratings and feedback',
  addNewLocation: 'Add new location',
  addNewCity: 'Add new city',
  editCity: 'Edit city',
  operatorName: 'Location name',
  address: 'Address',
  city: 'City',
  country: 'Country',
  seeExample: 'See example',
  website: 'Website',
  websiteTooltip:
    'You can add a link to your own website. The link is shown with your location details on the Spacent website.',
  websiteUrl: 'Website URL',
  metaTitleText: 'Meta Title Text',
  metaTitleTextTooltip:
    'Fill in: "location name - city - Spacent". This title is usually shown in search engine results.',
  metaTitleTextDefault: 'Workspaces, hot desks and meeting rooms',
  metaDescription: 'Meta Description',
  metaDescriptionTooltip:
    'Fill in: "Coworking space in [city]. One or two descriptive sentences about the space. Reserve your workstation on the Spacent app!” This description is usually shown in search engine results.\nIn Finnish: "-tila [kaupungissa]. Lause tai kaksi, jotka kuvaavat kohdetta. Varaa työpisteesi Spacentin sovelluksella!"\nIn Swedish: "Coworking space i [city]. One or two descriptive sentences about the space. Reservera din arbetsstation på Spacent-appen!"',
  metaDescriptionDefault:
    'We enable remote working everywhere. As a Spacent member, you can work from thousands of coworking spaces in Europe.',
  metaKeywords: 'Meta Keywords',
  metaKeywordsTooltip: 'Separated by comma',
  openGraphTitleText: 'Open Graph Title Text',
  openGraphTitleTextTooltip:
    'Fill in: “location name - city - Spacent”. This is the social share title of the page.',
  openGraphTitleTextDefault: 'Workspaces, hot desks and meeting rooms',
  openGraphDescription: 'Open Graph Description',
  openGraphDescriptionTooltip:
    'This is the social share description of the page.',

  // location editor -- admin
  locationAdded: 'Your new Location is added! 🎉',
  locationAddError:
    'Oh oh 😭. There was an issue adding your location, could you please check your new location information?',
  locationSaved: 'Your Location has been saved! 🎉',
  locationSaveError:
    'Oh oh 😭. There was an issue updating your location, could you please check your location information?',
  locationEdit: 'Edit Location',
  locationAdd: 'Add a new Location',
  generalInformation: 'General Information',
  operatorNameHelper: 'Primary name shown in the application',
  addressHelper: 'Street address, postal code and city',
  cityHelper: 'Name of city only, to help spot locations there',
  operatorNameExists: 'already exists',
  coordinates: 'Coordinates',
  latitude: 'Latitude',
  longitude: 'Longitude',
  selectOnMap: 'Select on map',
  similarLocation: 'Similar location',
  nearbyLocation:
    'There is another location under 30 meters from the current one',
  locationInfo: 'Location Info',
  lobbyServicesOpeningHours: 'Lobby Services Open',
  lobbyNotAvailable: 'Not available',
  lobbyAlwaysOpen: 'Available 24/7',
  contactInfo: 'Contact Info',
  description: 'Description',
  locationDescriptionPlaceholder:
    'Please fill in description for the location, such as services (e.g. restaurant or cafe services in the location or nearby), public transport connections, parking, information of the space concept, additional contact information and COVID-19 measures implemented in location.',
  arrivalInfo: 'Arrival Info',
  arrivalInfoPlaceholder:
    'Info to be shown for a visitor after they make a booking: how to enter location, access codes, key control instructions etc.',
  healthCommitmentTitle: 'Title',
  healthCommitmentTitlePlaceholder: 'Health Commitment',
  healthCommitmentContent: 'Content',
  healthCommitmentContentPlaceholder:
    "Please go through the following checklist to ensure everyone in the space you're about to reserve stays safe and healthy.",
  healthCommitmentChecks: 'Current checks',
  healthCommitmentChecksPlaceholder1: "I'm not showing any COVID-19 symptoms",
  healthCommitmentChecksPlaceholder2:
    'No contact with others with confirmed positive test of COVID-19 in 14 days',
  healthCommitmentChecksPlaceholder3:
    "I haven't travelled to restricted countries in the last 14 days",
  healthCommitmentNoChecks: 'No checks in place yet',
  healthCommitmentAddCheck: 'Add check',
  specialOpeningHoursClosed: 'closed',
  specialOpeningHours: 'Special opening hours',

  phone: 'Phone',
  phoneHelper: 'Phone number in international format, e.g. +12345678',
  sms: 'Number for Text Messages',
  smsHelper: 'Number in international format, e.g. +12345678',
  email: 'Email',
  sendBookingNotifications: 'Send email booking notifications to this address',
  bookingNotificationLanguage: 'Language of the notifications',
  calendarIntegration: 'Booking system integration',
  refreshCalendars: 'Refresh',
  openingHours: 'Opening hours',
  openingHoursDescription:
    'The location opening hours can also be overridden for individual spaces in the location',
  wifi: 'Wi-Fi',
  wifiName: 'Wi-Fi name',
  wifiPassword: 'Wi-Fi password',
  locationPhoto: 'Photo',
  onlyCoverPhoto: 'Note that only the cover image will be shown.',
  locationPhotoOpenGraph: 'OG',
  locationPhotoWeb1: 'Web1',
  locationPhotoWeb2: 'Web2',
  issuesInForm:
    'There are issues with some of the form items above. Please check.',
  dragPinToEntrance: 'Drag the pin to the location entrance',
  searchByAddress: 'Search by address',
  amenities: 'Amenities',
  locationGeneralInformationHelp:
    'GENERAL INFORMATION for the location is shown in the building info page.',
  operatorNameHelp:
    'Location name will be show in the list of spaces as your concept name. It can be the name of your coworking concept or the name of your building.',
  coordinatesHelp: 'Drag the pin on the map to the entrance of your space.',
  amenitiesHelp:
    'CHOOSE AMENITIES that are included in the price of the reservation. Some amenities can be only bought additionally on site, in these cases also choose "available for extra cost"',
  locationInfoHelp:
    'DESCRIPTION is shown in the Building info page. Please fill in description for the location, such as services (e.g. restaurant or café services in the location or nearby), public transportation connections, parking, information of the space concept, and additional contact information.\nThis description is shown on the Spacent web pages as well.',
  arrivalInfoHelp:
    'ARRIVAL INFO will be shown to the visitor after the booking is completed. A good arrival info communicates to the visitor, how to enter the location and how to get access. For example: Please check-in in the lobby upon arrival and show your reservation from the Spacent app.',
  healthCommitmentHelp:
    'HEALTH COMMITMENT is a health check for the visitor while they are completing their booking. They need to check all the boxes to be able to make the booking. You may revise the checks according to your COVID-19 policy.',
  lobbyServicesOpeningHoursHelp:
    'LOBBY SERVICES OPENING HOURS are shown in the building info page. This does not determine the availability of your spaces for bookings, this is the hosted lobby availability in the location for your visitors.',
  lobbyUsergroupHelp:
    'Lobby usergroup members who also have the reservations administration privilege can see and manage reservations in this location even if they are not members of the same organization.',
  contactInfoHelp:
    'CONTACT INFO is shown in the building info page. This communicates the visitor your contact information if they have additional questions or they want to be in contact in terms of additional services.',
  contactEmailHelp:
    'If you want to get email notifications for reservations and cancellations, check the box below. You may enter multiple email addresses.',
  openingHoursHelp:
    'OPENING HOURS determine the time when spaces in this location are available for reservations in the application. ',
  wifiHelp:
    'These wi-fi connection instructions are shown to a visitor after they make a booking.',
  locationPhotoHelp:
    'The app picture is shown in the app. A good app picture is about the building or of the entrance of the building where the space is located.\nThe web 1 picture is shown in the locations list and in the location view on the Spacent website.\nThe optional web 2 picture is shown in the location view on the Spacent website.',
  locationPhotoHelpSuperAdmin:
    'The cover picture is shown in the app. A good cover picture is about the building or of the entrance of the building where the space is located.\nThe web 1 picture is shown in the locations list and in the location view on the Spacent website.\nThe optional web 2 picture is shown in the location view on the Spacent website.\nThe OG picture is used as OpenGraph image for SEO.',
  blockReservingOnNationalHolidays: 'Block reservations on public holidays',
  publicHoliday: 'Public holiday',
  createWebPage: `Create a public web page`,

  // amenities editor -- admin and website
  forExtraCost: 'Available for extra cost',
  locationSpecificInfo: 'Additional information',
  print_scan_copy: 'Print / scan / copy',
  coffee_tea: 'Coffee and tea',
  restaurant: 'On-site restaurant',
  cafe: 'On-site cafe',
  hand_sanitizer: 'Hand sanitizer',
  enhanced_cleaning: 'Enhanced cleaning',
  parking: 'Dedicated parking area',
  accessible: 'Accessible',
  lockers: 'Lockers',
  reception: 'Hosted reception',
  phone_booth: 'Phone booth',
  gym: 'Gym',
  adjustable_table: 'Adjustable table',
  monitor: 'External monitor',
  silent_space: 'Silent space',
  whiteboard: 'Whiteboard or flipchart',
  projector: 'Projector or similar',
  videoconference: 'Videoconference possibility',
  furnished: 'Furnished (private offices)',
  pet_friendly: 'Pet friendly',
  shower: 'Shower',
  bike_parking: 'Bike parking',
  no_pets: 'No pets',

  // image uploader -- admin
  marketingImageCover: 'MARKETING',
  imageUploaderCover: 'APP',
  imageUploaderNew: 'NEW',
  imageUploaderAdded: 'New. Click to remove.',
  imageUploaderRemoved: 'Removed. Click to add again.',
  imageUploaderNoChange: 'Current image. Click to remove.',
  imageUploaderUploading: 'Uploading...',
  imageUploaderAlt: 'Edit alt texts',
  imageUploaderDropHere:
    'Drop image files here or click to select from your computer',
  imageUploaderDragToReorder: 'Drag to reorder images',
  imageUploaderUnsavedChangesInfo:
    'Your changes will be reflected after saving',
  imageUploaderUnsavedChanges: 'Unsaved changes',
  imageUploaderDownloadImage: 'Download original image',
  setMarketingImage: 'Set as the marketing quality image',
  unsetMarketingImage: 'Unset marketing quality',

  // announcements -- admin
  sendAnnouncement: 'Send announcement',
  selectBuildings: 'Select buildings',
  noBuildings: 'No buildings available',
  users: 'users',
  announcementsToOwnEmail:
    "We'll also send the announcement to your registered email address",
  announcementSubject: 'Subject',
  announcementMessage: 'Message',
  announcementConfirmation:
    'Send announcement to all users of the selected buildings?',

  // feedback -- admin
  feedbackLocations: 'Location ratings and feedback',
  writtenFeedbackCount: 'Number of feedback',
  locationFeedback: 'Feedback for',
  openFeedback: 'Written feedback',
  noOpenFeedback: 'No written feedback for this location',
  noFeedbacksToShow: 'No feedbacks to show',
  hideRatingFeedback: 'Hide ratings without open feedback',
  ratings: 'Ratings',
  ratingsTooltip:
    'Numbers of each 1-5 rating that have been given for this location',
  rating: 'Rating',
  numberOfFeedback: 'Ratings given',
  respond: 'Respond',
  response: 'Response',
  respondToFeedbackTitle: 'Respond to reservation feedback',
  readyToSendConfirm: 'Are you ready to send this message?',
  send: 'Send',
  aboutFeedbackResponseRecipient: 'About the recipient',
  aboutFeedbackResponseDescription: `
        Recipient will receive this message through email, and their follow up
        conversation will be handled through Spacent support. This allows you as
        a host to address customers concerns yet still protecting end users
        privacy.`,
  responseSent: 'Feedback response sent',

  // building management -- admin
  updateBuildingManagements: 'Update building management info',
  buildingName: 'Building name',
  managementFetchError: 'Oh oh 😭. Building management info not found',
  managementSaved: 'Building management info has been saved! 🎉',
  managementSaveError:
    'Oh oh 😭. There was an issue updating the building management info.',
  editBuildingManagement: 'Edit Building Management',
  maintenanceEmails: 'Maintenance emails',
  maintenanceEmail: 'Email',
  addEmail: 'Add email',
  buildingDescription: 'Building description',
  buildingDescriptionDescription:
    "This description will be shown for visitors reading up info about the building. If there already is a description for the operator's location in the building, this text will be shown after that description.",
  buildingDescriptionPlaceholder:
    'Contact details and further info on building maintenance, etc.',

  buildingPhoto: 'Photo',
  buildingPhotoDescription:
    'This cover photo will be shown for visitors reading up info about the building, if no photo about the location has been added.',
  photos: 'Photo',

  // spaces -- admin
  spaceAdded: 'Your new Space is added! 🎉',
  spaceAddError:
    'Oh oh 😭. There was an issue adding your space, could you please check your new space information?',
  spaceSaved: 'Your Space has been saved! 🎉',
  spaceSaveError:
    'Oh oh 😭. There was an issue updating your space, could you please check your space information?',
  spaceHidingCleared: 'The hiding of the space has been cleared.',
  spaceHidingClearError:
    'Oh oh 😭. There was an issue clearing the hiding of the space.',
  spaceNotFoundError: 'Oh oh 😭. Space was not found.',
  addNewSpace: 'Add new space',
  spaceName: 'Space name',
  integrations: 'Integrations',
  location: 'Location',
  capacity: 'Capacity',
  avgRating: 'Avg rating',
  hideFromCalendars: 'Block reservations',
  spacesHidden: 'The spaces will now be hidden.',
  spacesHiddenError: 'Oh oh 😭. There was an issue hiding the spaces',
  hiddenDueUnavailability: 'Reservations blocked temporarily.',
  hideFromBookingCalendars: 'Block reservations for a time',
  hideTimeSelection: 'Block reservations for these days',
  hideSpaceSelection:
    'These temporary changes in opening hours affect the following spaces',
  hideSpaceLocationTimezoneNote:
    "Your current timezone might differ from the spaces' timezone, but you don't have to worry about it. For example, if the space is closed on Tuesday 23rd in that timezone, you can just set it as Tuesday 23rd and we'll make sure it's correct.",
  hideFrom: 'From',
  hideUntil: 'Until',
  hideDays: 'Number of days',
  hideAllDay: 'Hide all day',
  existingReservations: 'Existing reservations',
  viewAndCancelReservations: 'View and cancel reservations',
  hideSpaceDescription: 'Reason for the block or custom opening hours',

  setCustomOpeningHours:
    'Set limited opening hours (only block for couple of hours)',
  openHoursNotExpandable:
    'Below you can set the opening hours for the day(s) - choose the hours when the space is open. Opening hours can only be limited, and not extended, here.',
  offerSuffix: 'offer',
  name: 'Name',
  activity: 'Activity',
  activityRecent: 'Within 6 months',
  activityLong: 'Over 6 months ago',
  activityNo: 'No activity',
  peopleManagementGuideTitle: 'About people management',
  peopleManagementGuideDomains:
    'New users will be added automatically when they log in using the company domain ',
  peopleManagementGuideNewUsers:
    'New users need to be added by Spacent support.',
  peopleManagementDisabledUsers:
    'Disabled users cannot be activated again. If you need further assistance, please contact Spacent support.',
  noUsers: 'No users',
  pricing: 'Pricing',
  hours: 'Hours',
  days: 'Days',
  weeks: 'Weeks',
  months: 'Months',
  hour: 'Hour',
  day: 'Day',
  week: 'Week',
  month: 'Month',
  perMonth: 'per Month',
  price: 'Price',
  editSpace: 'Edit space',
  spaceNamePlaceholder: 'Flexible seats',
  spaceDescriptionPlaceholder:
    'e.g. description of the space, equipment, sockets, furniture...',
  floor: 'Floor',
  inheritFromLocation: 'Inherit from the location',
  customHours: 'Custom opening hours for this space',
  customArrivalInfoForSpace: 'Custom arrival info for this space',
  spaceDescription: 'Space description',
  spaceDescriptionDescription:
    'For better visitor experience, please provide description also in native language of your guests if possible.',
  spaceArrivalInfoDescription:
    'Custom arrival instructions for this space only. When not set, arrival info of building is used instead.',
  spaceTypeLabel: 'Type',
  spaceTypeCapacity: 'Multiple desks',
  numberOfDesks: 'Number of desks',
  spaceTypeMeetingRoom: 'A meeting room',
  spaceTypeProjectRoom: 'A private room',
  spaceTypeOther: 'Other space type',
  spaceTypeTeam: 'A team room',
  meetingRoomCapacity: 'Space capacity',
  otherCapacity: 'Capacity',
  displayCapacity: 'Display capacity in app',
  allowedDaysMax: 'Longest reservation length in days',
  noPricingSet: 'No pricing set',
  notSet: 'Not set',
  sellForFixedRate: 'Sell for fixed hourly rate',
  priceExcludesTaxes: 'Price excludes taxes',
  currency: 'Currency',
  availability: 'Availability',
  freeForOwnOrganization:
    'Members of your organization can reserve this space free of charge',
  spacePhotos: 'Photos',
  spaceHidden: 'Visibility',
  spaceHiddenDescription:
    'Reservations will be blocked from all reservations on:',
  spaceCustomOpeningHours:
    'Days with custom opening hours (space is open during this time):',
  offer: 'Offer',
  offering: 'Offering',
  fieldsMissing: 'Some required fields are still missing',
  saveAndPublish: 'Save & publish',
  saveAndSendForReview: 'Save & send for review',
  duplicate: 'Duplicate',
  hourlyPricingDisabledForCapacity:
    'If you want to change desk pricing, please contact us!',
  dateRangePickerFrom: 'From',
  dateRangePickerTo: 'To',
  dateRangePickerUntil: 'Until',
  reservationBuffer: 'Gap between reservations',
  reservationBufferBefore: 'Pause before use (minutes)',
  reservationBufferAfter: 'Pause after use (minutes)',
  spaceLocationHelp:
    'FIRST CHOOSE THE LOCATION where your space is located. If it’s a new location and you can’t find it from the list, create a new location first and then return to create spaces.',
  spaceNameHelp:
    'A GOOD SPACE NAME describes the type of the space resource. For example Hot desks, Flexible seats, Coworking space or Meeting room Alpha.',
  spaceOpeningHoursHelp:
    'OPENING HOURS determine when the space is available for reservations and can be inherited from the location or customized for this exact space. If you select custom opening hours, they will overwrite the location opening hours.',
  spaceAmenitiesHelp:
    'AMENITIES are shown in the space page. Choose the amenities available in this specific space and included in the price of the reservation.',
  spaceDescriptionHelp:
    'SPACE DESCRIPTION is shown on the space page. Short yet descriptive text about the space, what kind of furniture is available, sockets, equipment, open or quiet area etc.',
  spaceCapacityHelp:
    'Offer space as either individual hot desks, a meeting room on an hourly basis, or a project room for longer periods. For multiple desks, set capacity to the number of desks you can offer for individual users; otherwise, set it to how many people the room is suitable for.',
  reservationBufferHelp:
    "If the space needs pre-arranging before use or cleaning after use, you can set a time before and after each booking during which the space can't be booked.",
  spacePricingHelp:
    'For desks, pricing is set in the agreements. For meeting rooms, please select an hourly rate.',
  spaceAvailabilityHelp:
    'By checking this box, the members of your own organization (e.g. lobby services or space administration team members) can reserve the spaces in the Spacent App free of charge.',
  spacePhotosHelp:
    'SPACE PICTURES will be shown in the space page. You may add multiple pictures and they will all be shown in Spacent App. The cover picture will be shown first. Good space pictures are descriptive of the space you will be assigned to work in and can contain also pictures from the common areas.',
  integrationIdentifier: 'Space Identifier',
  status: 'Status',
  live: 'Live',
  hidden: 'Hidden',
  draft: 'Draft',
  inReview: 'In review',
  internal: 'Internal',
  'hidden-from-calendars': 'Reservations blocked',
  testing: 'Testing',
  deleted: 'Deleted',
  confirmDeletion: 'Are you sure you want to delete this space?',
  confirmPublishingStatusChange:
    "Are you sure you want to change the space's visibility?",
  saveAsADraft: 'Save as a draft',
  offerActive: 'Active',
  offerLabel: 'Label',
  offerPricing: 'Pricing',
  offeringReservableFor: 'Reservable for',
  offerPricingAndAvailability: 'Pricing & Availability',
  offerNotes: 'Notes',
  consumerSalesTitle: 'Consumer sales',
  sellForConsumers: 'Allow consumers to make reservations for this space',
  sellForConsumerClarification: 'This does not have impact on pricing',
  offerFree: 'Free',
  offerVolume: 'Unit pricing',
  offerVolumeTooltip:
    'E.g. A reservation of 2w and 3d will cost 2 * weekly price + 3 * daily price.',
  offerTieredByUnit: 'Tiered, pricing per unit',
  offerTieredByUnitTooltip:
    'E.g. Tiered (1h, $20) and (3h, $15), a reservation of 2 hours costs $40 and a 3 hour reservation costs $45.',
  offerTieredByLength: 'Tiered, total pricing by length',
  offerTieredByLengthTooltip:
    'E.g. tiers of (1h/$20) and (3h/$50), a reservation of 2 hours falls into the 3h tier and will cost $50.',
  offerTieredHoursFrom: 'From',
  offerTieredHoursTo: 'To',
  offerCreateNewPricing: 'Create new pricing',
  offerHourlyPrice: 'Hourly tax exclusive price',
  offerDailyPrice: 'Daily tax exclusive price',
  offerWeeklyPrice: 'Weekly tax exclusive price',
  offerMonthlyPrice: 'Monthly tax exclusive price',
  units: 'Units',
  offerRestrictedEditingMessage:
    'This pricing has special meaning behind the scenes, thus some editing capabilities are disabled. Feel free to contact us so we can tweak these for you!',
  offerTaxExclusivePricePer: 'Tax excl. price per',
  offerAddNewTier: 'Add new tier',
  offerMaxReservationHoursBeforeStart:
    'Maximum hours before reservation starts',
  offerMaxReservationTooltip: `If you want to limit the earliest time a user can make the reservation before it's start.`,
  offerMinReservationHoursBeforeStart:
    'Minimum hours before reservation starts',
  offerMinReservationTooltip: `If you want to limit the latest time a user can make the reservation before it's start.`,
  offerPricingSpecialMeaning:
    'This pricing has some special meaning behind the scenes and the editing is disabled from other than super-admins.',
  offerDeletePricingConfirmationMessage:
    'Are you sure you want to delete this pricing?',
  offerReservationLimits: 'Reservation limits',
  offerMaxReservationHours: 'Maximum reservation time in hours',
  offerMinReservationHours: 'Minimum reservation time in hours',
  offerMaxReservationDays: 'Maximum reservation time in days',
  offerMinReservationDays: 'Minimum reservation time in days',
  offerMaxReservationHoursPerDay: 'Maximum reservation hours per day',
  offerMaxUpcomingReservationsPerUser:
    'Maximum allowed upcoming reservations per user',
  showAllPricings: 'Show all pricings',
  addNewPricing: 'Add new pricing',
  offerFreeForOrganizationMembers:
    'Free reservation for own organization members',
  setFixedHourlyPricing: 'Set a fixed hourly rate',
  setSimplePricing: 'Set simple pricing',
  setCustomPricing: 'Set custom pricing',
  setPricePerAccess: 'Set a price per access',
  taxExclusivePricePerAccess: 'Tax exclusive price per access',
  pricingUpdated: 'Pricing updated',
  pricingHasBeenChanged: 'Your pricing changes have been saved.',
  ohNo: 'Oh no.',
  somethingWentWrongWithThePricing:
    'Something went wrong with updating the pricing. ',
  pricingHasBeenDeleted: 'Pricing has been deleted',
  pricingHasBeenRemovedFromSpaces:
    'The pricing has been removed from every space and deleted.',
  somethingWentWrongDeletingPricing:
    'Something went wrong while trying to delete the pricing.',
  tieredByLength: 'Tiered by length',
  totalTaxExclusivePrice: 'Total tax exclusive price',
  offerChangesAffectFollowingSpaces:
    'The following spaces will be affected by these changes, effective immediately.',
  offerChangesAffectsNoSpaces: 'Offer is not connected to any spaces yet.',
  note: 'Note',
  offerSpaceForBasicMembershipHolders:
    'Add this room to be shown "In Plan" for Basic Membership users',
  offerSpaceForBusinessMembershipHolders:
    'Add this room to be shown "In Plan" for Business Membership users',
  offerSpaceForBusinessPlusMembershipHolders:
    'Add this room to be shown "In Plan" for Business Plus Membership users',
  offerSpaceForMembershipHolders:
    'Add this room to be shown "In Plan" for Coworking Pass users',
  connectedSpaces: 'Connected spaces',
  connectedSpacesTooltip:
    'If any of the connected spaces is booked, all others are unavailable during the booking',
  connectedReservation: 'Reservation is made into a connected space',
  duplicateConfirmationTitle: 'Are you sure you want to duplicate the space?',
  duplicateConfirmationText:
    'This will create a new space and copies all of the information from this space.',
  setReservationLimits: 'Set reservation limits',
  offerLimitationsOverrideWarning:
    "Adding these limitations will override room's default reservation limits, if it has any.",
  earliestTimeToMakeReservation: 'Earliest time to make a reservation',
  latestTimeToMakeReservation: 'Latest time to make a reservation',
  someHoursBeforeStart: 'Hours before start',
  someDaysBeforeStart: 'Days before start',
  previousDayAtTime: 'Previous open day before...',

  // People management -- admin
  done: 'Done!',
  nowReactivated: 'has now been re-activated.',
  nowRemoved: 'has now been removed.',
  removeUserTitle: 'Remove user?',
  removeUserBody:
    'Are you sure you want to remove user? This will log the user out immediately and prevent making any new reservations.',
  orgHasNoUsers: 'Your organization does not have any users.',
  disabledUsers: 'Disabled users',

  // user groups -- admin
  addUsergroup: 'Add new usergroup',
  usergroupCreated: 'Your new usergroup was added! 🎉',
  usergroupCreateError: 'Oh oh 😭. There was an issue adding your usergroup...',
  usergroupSaved: 'Your usergroup has been updated! 🎉',
  usergroupSaveError: 'Oh oh 😭. There was an issue updating your usergroup...',
  inviteListFormatError:
    'Input contains invalid email, or is not separated correctly',
  invitesSent: 'Invitations sent succesfully 🎉',
  invitesSendError: 'Error while sending invites 😭',
  membersOfGroup: 'Members of',
  editUsergroup: 'Edit group',
  inviteAddresses: 'Email addresses to invite',
  sendInvites: 'Send invites',
  pendingInvites: 'Pending invitations',
  usersInGroup: 'Users in group',
  usergroupChangesImmediate:
    'Changes to this usergroup take effect immediately.',
  usergroupCreateImmediate: 'Usergroup will be created immediately.',
  lobbyUsergroupAssociationSaveDelayed:
    'Association with the location will be established once you save the main form.',
  detachUsergroup: 'Detach usergroup',
  selectExistingUsergroup: 'Select an existing usergroup',
  lobbyUsergroup: 'Lobby usergroup',
  byVolumeEditorFillBlanks: 'Calculate missing prices',

  // settings -- admin
  yourAccount: 'Your account',
  yourOrganization: 'Your organization',
  logout: 'Log out',

  // checkin
  checkIn: 'Check in',
  checkInHeader: 'Let the organizer know you are here',
  checkInAddress: 'Check-in address',
  checkInFormEmailPlaceholder: 'name@example.com',
  checkInPhone: 'Your phone number with country code (eg. +46778899)',
  checkInFormPhoneError: 'Please enter a valid phone number',
  checkInFormNameError: 'Your full name is required',
  checkInErrorAlreadyCheckedIn: 'You have already checked in with this email',
  checkInErrorInvalid: 'Invalid check-in',
  checkInSuccessHeader: 'Success!',
  checkInSuccessDesc: `You've checked in! We've notified the organizer of your arrival. If you have any problems, please contact them directly or call us`,
  checkInNoReservation: 'You can check in on the reservation day.',
  checkinSmsText: `A guest you invited to join you at your Spacent reservation has just arrived!`,

  // login -- admin
  loginToAdmin: 'Login to Spacent Management Tool',
  adminLoginUnauthorized:
    "Unfortunately you're not authorized to access the Spacent Management Tool.",
  adminLoginError: 'Login failed.',
  adminLoginEmailAuth:
    'We have sent you a login link! Please check your email and click or copy the link to your browser. The email may take several minutes.',
  adminLoginEmail: 'example@spacent.com',
  adminLoginTryAgain: 'Please try logging in again at',
  adminLoginRetryInstructions:
    'Please make sure that you use the most recent link you receive. Receiving the email may take several minutes.',
  adminWelcome: 'Welcome to Spacent Management',
  login: 'Log in',
  adminErrorContact: 'If you think this is a mistake, please contact',
  clickToRetry: 'Click here to try again',

  // login
  timeToCheckEmail: 'Time to check your email',
  emailSent:
    'We have emailed you a magic code to verify its you, please enter the code below.',
  verificationCode: 'Verification code',
  loginAttemptFailed:
    'We could not verify this login attempt. Please make sure you have the right code, or try logging in again.',

  // invites -- admin
  inviteStart: "Hi there! Let's get started with Spacent",
  inviteWelcome: 'Welcome to your distributed workplace!',
  invitedAccount: 'Invited account',
  inviteMicrosoftSso: 'Continue with Microsoft SSO',
  inviteGoogleSso: 'Continue with Google SSO',
  inviteEmailAuth: 'Continue with email link',
  inviteCheckEmail: 'Please check your email!',
  inviteCheckEmailDescription:
    'Email with verification link has been sent to your email:',
  inviteNotFound: "Unfortunately we couldn't find your invitation.",
  inviteError:
    'Something went wrong processing your invitation, please try again later.',
  inviteDoneExistingHeader: "You're good to go!",
  inviteDoneExistingContent:
    'Your organization has joined Spacent. Start using Spacent by downloading the app from the store. To gain invited pricing, use your invited email:',
  inviteDoneNewOrganization: 'Organization created',
  inviteDoneNewHeader: 'Welcome to the Spacent community!',
  inviteDoneNewContent:
    'We have now created your user and connected your organization to Spacent. Download the Spacent app. To start using your distributed workplace, sign in with your user account:',
  createAccount: 'Create account',

  // onboarding -- admin
  onboardingLoading: 'Loading Onboarding...',
  goBack: 'Go back',
  onboardingNotFound:
    'Unfortunately the referenced onboarding draft could not be found.',
  onboardingError: 'Something went wrong.',
  onboardingDetails: 'Details',
  onboardingSummary: 'Summary & Payment',
  onboardingDownload: 'Download app',
  onboardingDownloadTooltip: 'Complete all steps to access',
  introTitle: 'Join the Future of Office.',
  introLocationsFI: 'Workplace locations in Finland',
  introLocationsSE: 'Workplace locations in Sweden',
  introLocationsCA: 'Workplace locations in Canada',
  introDesks: 'Unlimited use of hot desks',
  introShare: 'Unlimited use of hot desks, for all users',
  introRooms: 'Pay-per-use meeting rooms and private offices',
  introPriceTitle: 'Price per person',
  onboardingDedicatedSubscriptionPriceUnit: 'For each employee',
  onboardingCorporateSubscriptionPriceUnit: 'For each 20 shareable accesses',
  onboardingAddDedicatedUsers: 'Add users to membership',
  purchaseSummary: 'Purchase summary',
  onboardingPerMonth: 'month',
  introCorporateAccess: 'Access to all workplace locations',
  introCorporateAccessFI: 'Access to all workplace locations in Finland',
  introCorporateAccessSE: 'Access to all workplace locations in Sweden',
  introCorporateAccessCA: 'Access to all workplace locations in Canada',
  introCorporateEmployees: 'Spacent access for all your employees',
  yourInfo: 'Your Info',
  subscriptionSelectType: 'Select a plan',
  dedicatedMembership: 'Personal Coworking Pass',
  corporateMembership: 'Shared Coworking Pass',
  payPerUse: 'Pay-per-use',
  amountOfMemberships: 'Amount of users',
  membershipTerminationPeriodTitle: 'Termination period',
  membershipTerminationPeriodContent: '3 months',

  numberOfUsers: 'Choose your business account type',
  suggestedAmountMemberships: 'Suggested amount of memberships',
  useValue: 'Use this amount',

  loginEmail: 'Login email',
  loginEmailDomain: 'Onboarding your employees',
  loginEmailDomainDescription: `Automatic onboarding with company email domain`,
  loginEmailDomainDetails: `Everyone with this email domain will be able to login to Spacen app and book spaces for your organization. You'll receive more instructions vie email.`,
  loginEmailDomainTitle: `Organization's email domain`,
  loginEmailManualDescription: `I'd like more information about the choices of onboarding`,
  loginEmailManualDetails:
    'We will be in touch within 1-2 business days to find the most suitable onboarding possibilities for your organization.',
  domains: 'Domain name',
  subscriptionUsersCount: 'Users: ',
  subscriptionTotal: 'Total price: ',
  subscriptionMembership: 'Spacent Personal coworking pass',
  corporateSubscriptionMembership: 'Spacent Shared coworking pass',
  subscriptionSalesEmail: 'Spacent sales',
  subscriptionCustomize: 'to customize a solution for your team.',
  subscriptionAdd: 'Add',
  subscriptionRemove: 'Remove',
  subscriptionEmailError: 'Plaese enter a valid email',
  onboardingInfoError:
    'Please check that you have completed all the necessary information.',
  organizationInfo: 'Organization Information',
  addressStreet: 'Street address',
  addressPostalCode: 'Postal code',
  addressCity: 'City',
  addressCountry: 'Country',
  paymentIAccept: 'I accept',
  paymentTermsAndConditions: 'The Spacent Terms and Conditions',
  paymentUserTerms: 'The User Terms',
  paymentPrivacyPolicy: 'The Privacy Policy',
  summary: 'Summary',
  startDate: 'Select service start date',
  payment: 'Payment',
  emailInvoice: 'Email invoice',
  electronicInvoice: 'Electronic invoice',
  order: 'Order',
  contact: 'Contact',
  summaryIncomplete: '(not completed)',
  otherMembers: 'Other members',
  fillDetailsForSummary: 'Fill in details to see a summary',
  onboardingDoneTitle: 'Welcome to the Future of Office!',
  onboardingDoneSuccess: 'You have succesfully registered to Spacent.',
  onboardingDoneDownload:
    'Download the Spacent application and sign in with your email',
  onboardingDoneFindSpace: 'to find your perfect space.',
  onboardingDoneEmail:
    'We have also sent you a confirmation email regarding your pass into your email.',
  identifier: 'Business Id',
  onboardingPaymentMethodNote:
    'Selected payment method will be used for all expenses of your organization, including pay-per-use reservations of members of the organization.',
  onboardingRegionFI: 'Finland',
  onboardingRegionSE: 'Sweden',
  onboardingRegionCA: 'Canada',
  onboardingOrganizationLocation: 'Select region',
  onboardingSelectPlan: 'Select Plan',
  onboardingAccountDetails: 'Your information',
  onboardingOrganizationDetails: 'Organization details',
  onboardingStartWithYourInfo: 'Your information',
  unitPrice: 'Unit price',
  basePrice: 'Base price',
  discountHeader: 'Discount',
  forOnePerson: 'For one person',
  forOneToTwentyUsers: 'For 1-5 users',
  startingFrom: 'Starting from',
  fillTheFormPrefixGB: `For a fixed fee for more than 5 users,`,
  fillTheFormPrefix: `For a fixed fee for more than 10 users,`,
  fillTheForm: 'ask for an offer',
  onboardingFormUrl: 'https://www.spacent.com/contact-us',
  fillTheFormSuffix: `!`,

  onboardingWelcomeStep: 'Welcome',
  onboardingInvoicingDetailsStep: 'Invoicing details',
  onboardingDoneStep: 'Onboarding your employees',
  onboardingFinishedTitle: 'Thank you for setting up your company!',
  imAnIndividual: 'I am not registering as an organization',
  businessCoworkingPassExplanation:
    'With the Business Monthly Pass, your team can reserve coworking desks at selected locations with no monthly usage limits — all for a single fixed monthly price. All coworking spaces marked as "In Plan" on the Spacent App are included in the monthly pass - explore them ',
  volumeBasedPassExplanation:
    'With the pay-per-use Business Account, your team can reserve coworking desks at selected locations and only pay for the reservations they make. The total invoice will vary based on the number of reservations made. Volume-based pricing is applied on all reservations made to coworking desks marked as "In Plan" on the Spacent App - explore them ',
  locationsWebsiteUrl:
    'https://www.spacent.com/locations?category=0&membershipPlan=3&minRating=0&s=&spaceType=desks',
  perUserLicense: '+ 5 € license fee / user / month',
  perUserLicenseGB: '+ 3 £ license fee / user / month',
  perUserLicenseSE: '+ 50 kr license fee / user / month',

  // Redeem codes
  discountCode: 'Discount Code',
  redeemDiscountCode: 'Redeem a Discount Code',
  discountCodeInstructions:
    'If you have a discount code, please enter it below',
  discountAlreadyApplied: 'A discount has already been applied.',
  sendDiscountCode: 'Send code',
  redeemCodeAddToUsergroupSuccess:
    'You are now able to access additional features in Spacent.',
  redeemCodeChangeOrganizationSuccess:
    'You are now able to access additional features in Spacent.',
  redeemCodeDiscount: 'Your subscription price is now discounted.',
  redeemCodePersonalMembershipDiscountRegionMismatch:
    'This discount code is not available for your location.',
  redeemCodeInternalError:
    'An internal error has occurred. Spacent support has been informed about this.',
  redeemCodeNotFound:
    'We could not find that code. Please check if you typed it in correctly.',
  redeemCodeUsed: 'You have already used this code. You should be all set!',
  redeemCodeNoDiscountForExistingSubscription:
    'This discount code can only be used before signing up.',
  redeemCodeOrganizationOverLimit:
    'Your company has reached its user limit. Please contact support@spacent.com to sign in.',

  vat: 'VAT',
  // TODO for canada but update when more types
  sales_tax: 'HST',
  eur: '€',
  usd: '$',
  cad: 'CAN$',
  sek: 'kr',
  cny: '¥',
  nok: 'kr',
  dkk: 'kr',
  ron: 'lei',
  gbp: '£',
  pln: 'zł',
  chf: 'fr',
  czk: 'Kč',

  // emails -- admin
  emailReservationFrom: 'from',
  emailReservationUntil: 'to',
  emailReservationView: 'You can view the upcoming reservation in ',
  emailReservationManagement: 'Spacent Management Tool',
  emailReservationContact:
    'If you have any questions, you can also contact us at',
  emailBestRegards: 'Best regards,',
  emailSpacentTeam: 'Spacent team',

  emailNewReservationTitle: 'You have a new reservation!',
  emailCancelReservationTitle: 'Reservation has been cancelled',

  emailYouWereAddedToGroup: 'You have been added to Spacent',
  emailMoreWorkspaces: 'You have more workspaces available in Spacent!',
  emailGreatCompany: 'You are in great company.',
  emailHello: 'Hi',
  emailAddedToGroup: "You've been added into a new usergroup.",
  emailOrganizationAddedToGroup: 'has added you into group',
  emailMoreWorkspacesAvailable:
    'This means that you now have even more workspaces available in the Spacent application.',

  emailWelcomeToBook: 'Welcome to book workspaces with Spacent!',
  emailWelcomeTitle:
    'You have been invited to join the future of office with Spacent!',
  emailWelcomeSpacentProvidesSolution:
    'provides a flexible solution for hybrid working - enabling you to work wherever and whenever needed.',
  emailWelcomeOrganizationHasInvited:
    'has invited you to join Spacent. Through Spacent application you can find and book a workspace when you need it.',
  emailWelcomeGetStarted: `Let's get you started:`,
  emailWelcomeGetApp: 'Get Spacent application.',
  emailWelcomeLogin: 'Login with your email',
  emailWelcomeFindYourSpot:
    'Search available spaces and make your first booking!',
  emailSpacentProvides:
    'provides your organization a flexible solution for dynamic workplace – enabling work wherever and whenever.',
  emailGetStarted: 'Let’s get you started',
  emailWelcome: 'Welcome',

  emailOnboardedAdminWelcome: 'Welcome to Spacent!',
  emailOnboardedAdminTitle:
    'Congratulations for joining the future of office with Spacent!',
  emailOnboardedAdminReady: 'and you are ready to go.',
  emailOnboardedAdminOrganization: 'Your organization',
  emailOnboardedAdminContact:
    "Your Spacent membership grows together with your organization and adapts to your needs. You can always modify or cancel your organization's Spacent membership by contacting us at",
  emailOnboardedAdminView:
    'You can also view and update your organization and invoicing information at',
  emailOnboardedAdminManagement: 'Spacent Management Tool',
  emailOnboardedAdminInvoicing: 'Invoicing',
  emailOnboardedAdminInvoicingDescription:
    'The monthly subscription will be invoiced according to the subscription order:',
  emailOnboardedAdminInvoicingItem: 'Item',
  emailOnboardedAdminInvoicingQuantity: 'Quantity',
  emailOnboardedAdminInvoicingPrice: 'Price',
  emailOnboardedAdminInvoicingMembership: 'Spacent coworking pass',
  emailOnboardedAdminInvoicingTotal: 'Total',
  emailOnboardedAdminInvoicingDiscount: 'Discount',
  emailOnboardedAdminDescription:
    "The membership covers all spaces marked as 'In plan'. If members of your organization book spaces not included in the membership, the pricing will be shown to the user and these bookings will be invoiced from your organization in addition to the monthly subscription.",
  emailOnboardedAdminTerms:
    'When joining Spacent you have accepted following terms and conditions: ',
  emailOnboardedAdminConfirmation:
    'If this registration was not made by you, please contact us at',

  emailOnboardingUserWelcome: 'Welcome to book workspace with Spacent!',
  emailOnboardingUserTitle:
    'Welcome to Spacent and congratulations for joining the future of office!',
  emailOnboardingUserUserAtOrganization: 'at',
  emailOnboardingUserInvitedYou: 'has invited you to join Spacent platform.',
  emailOnboardingUserSpacentProvides:
    'provides a flexible solution for dynamic workplace – through the Spacent App you can find and book a workspace when and where you need it.',
  emailOnboardingUserDescription:
    "Your Spacent membership covers all spaces marked as 'In plan'. Separately priced spaces will be invoiced according to the pricing shown in the application from",
  emailOnboardingUserMoreInfo:
    'You can find more information on the Spacent membership at',
  emailOnboardingUserContact: 'If you have questions please contact us at',

  emailOnboardingChoiceDomain:
    'You can provide us with your work email domain. If you choose to onboard with company domain, your whole organization is able to login to the application using their work email (the domain you have provided) and access the corporate membership. The default verification is email authentication, but we also support Google and Azure authentication.',

  emailOnboardingChoiceManual:
    'We will contact you so that you can define users you wish to include into your corporate membership.',
  emailOnboardingWeWillCallYou:
    "We'll be in touch during the next business day to onboard the rest of your organization seamlessly!",

  emailAuthSubject: 'Signing in to Spacent',
  emailVerificationSubject: 'Verify your email address for Spacent',
  emailAuthTitle: 'Here is your login info to Spacent :)',
  emailAuthGreeting: 'Hi',
  emailAuthLoginLink:
    'Here is your unique login link. After clicking it, you will be redirected to Spacent with your account logged in.',
  emailAuthCodeInfo:
    'Here is your unique login code. After entering it when prompted, you will be logged in to Spacent. Code will be valid for one hour.',
  emailVerificationCodeInfo:
    'Here is your verification code. Enter it where prompted to verify your email. Code will be valid for one hour.',
  emailVerificationTitle: 'Here is your verification code to Spacent :)',
  emailAuthNote: 'NOTE',
  emailAuthAppPressLoginLink:
    'Make sure to press the login link on your phone that has the Spacent app!',
  emailAuthAppPressHereToLogin: 'Press here on your phone to login',
  emailAuthWebPressHereToLogin: 'Click here to login',
  emailAuthIfButtonDoesNotWork:
    'If the button does not work for some reason, you can also copy the following link address to your browser address bar. This login link is valid for one hour.',
  emailAuthContactUs: "Don't hesitate to contact us at ",
  emailAuthContactUsLinkText: 'support@spacent.com',
  emailAuthContactUsIfProblems: 'if you have any issues what so ever!',

  emailAuthorizePaymentSubject:
    'Spacent subscription payment requires authorization',
  emailAuthorizePaymentTitle: 'Authorize subscription payment',
  emailAuthorizePaymentBody:
    'The credit card attached to subscription requires authorizing a pending subscription payment. If you are the owner of the credit card, you can go to verify and authorize the payment by clicking the button below.',
  emailAuthorizePaymentButtonDoesntWork:
    'If the button does not work for some reason, you can also copy the following link address to your browser address bar.',
  emailAuthorizePaymentButton: 'Click here to authorize the payment',

  // organizations -- super-admin
  emailDomainExplanation:
    'Everyone with email in specified domains will be registered as members of this organization.',
  emailDomainWarning:
    'Do not add domains that are not owned by and limited to this organization!',
  emailDomainRejection:
    'Domain you have selected is unavailable or already used by another organization',
  emailProjectSpaceSubject: 'New Private office reservation',
  emailProjectSpace: 'You have booked the following private office: ',
  emailProjectSpaceArrivalInfo: `Here's what to do when arriving at your reserved space`,
  referenceInstructions: 'Reference instructions',
  instructions: 'Instructions',

  // atom / molecule specific
  pickerComponentOk: 'OK',
  pickerComponentCancel: 'Cancel',
  pickerComponentToday: 'Today',
  openingHoursClosed: 'Closed',
  openingHoursAlwaysOpen: 'Open 24/7',
  openingHoursCustom: 'Custom',
  localizableContentAdd: 'Add',

  // errors
  authError: 'Access denied. Please relogin.',
  reservationsFetchError:
    'There was an error while getting the reservations. Try again later.',
  reservationCreationError:
    'There was an error while creating a new reservation. Try again later.',
  reservationCancelError: 'Unable to cancel reservation; try again in a minute',
  reservationConflictError:
    'Unable to create reservation; room is already reserved.',
  reservationUpdateError: 'Unable to update then reservation; try again later',
  reservationUpdateConflictError:
    'Unable to update the reservation; then room is already reserved',
  spacesFetchError:
    'There was an error while getting the rooms. Try again later.',
  addCardError: "Couldn't add credit card",
  createOrganizationError:
    'Unfortunate error. Unable to join to Spacent. Please check your data and try again.',
  generalCreditCardError:
    'Unfortunately we were not able to setup billing with this card information.',
  retryCreditCardError:
    'Unfortunately we were not able verify your credit card. Please try again',
  reservationTimeNotAllowed:
    'Reservation is not allowed for given time period.',
  conflictingReservation:
    'The space is already reserved for the given time, please select another time.',

  // Stripe error messages
  stripe_card_declined: 'Your card has been declined.',
  stripe_card_declined_card_not_supported: 'Your card is not supported.',
  stripe_card_number_in_name_field:
    "Please make sure that the name field doesn't contain a card number.",
  stripe_card_number_in_postal_code_field:
    "Please make sure that the postal code field doesn't contain a card number.",
  stripe_customer_canceled_authentication:
    'You must authenticate to complete this transaction.',
  stripe_email_invalid: 'Invalid email address.',
  stripe_expired_card: 'Your card has expired.',
  stripe_incomplete_au_bank_account_bsb: 'The BSB you entered is incomplete.',
  stripe_incomplete_au_bank_account_number:
    'The account number you entered is incomplete.',
  stripe_incomplete_card: 'Please fill in your card details.',
  stripe_incomplete_cvc: "Your card's security code is incomplete.",
  stripe_incomplete_expiry: "Your card's expiration date is incomplete.",
  stripe_incomplete_iban: 'The IBAN you entered is incomplete.',
  stripe_incomplete_number: 'Your card number is incomplete.',
  stripe_incomplete_payment_details: 'Please provide complete payment details.',
  stripe_incomplete_zip: 'Your postal code is incomplete.',
  stripe_incorrect_cvc: "Your card's security code is incorrect.",
  stripe_incorrect_number: 'Your card number is incorrect.',
  stripe_incorrect_zip: 'Your card number and postal code do not match.',
  stripe_invalid_au_bank_account_bsb: 'The BSB you entered is invalid.',
  stripe_invalid_au_bank_account_number_testmode:
    'The account number you entered is not valid in testmode.',
  stripe_invalid_cvc: "Your card's security code is invalid.",
  stripe_invalid_expiry_month: "Your card's expiration date is invalid.",
  stripe_invalid_expiry_month_past:
    "Your card's expiration date is in the past.",
  stripe_invalid_expiry_year: "Your card's expiration year is invalid.",
  stripe_invalid_expiry_year_past:
    "Your card's expiration year is in the past.",
  stripe_invalid_iban: 'The IBAN you entered is invalid.',
  stripe_invalid_iban_country_code:
    'The IBAN you entered is invalid, "{code}" is not a supported country code.',
  stripe_invalid_iban_start:
    'Your IBAN should start with a two-letter country code.',
  stripe_invalid_number: 'Your card number is invalid.',
  stripe_payment_intent_authentication_failure:
    'We are unable to authenticate your payment method. Please choose a different payment method and try again.',
  stripe_payment_intent_unexpected_state: 'A processing error occurred.',
  stripe_process_error_intransient:
    'An error occurred while processing your card.',
  stripe_processing_error:
    'An error occurred while processing your card. Try again in a little bit.',
  stripe_setup_intent_authentication_failure:
    'We are unable to authenticate your payment method. Please choose a different payment method and try again.',
  stripe_setup_intent_unexpected_state: 'A processing error occurred.',
  stripe_unexpected: 'An unexpected error occurred.',

  // Accessy integration
  accessyInvitation:
    'You will be able to access spaces you book through the Spacent app.',
  accessyAcceptInvitation:
    'You have been been sent a text message invitation to set up the Accessy app to unlock the door to the space. Please follow the link in the text message to set up Accessy.',

  // invite a guest
  verify: 'Verify',
  acceptInvite: 'Accept invite',
  acceptInvitePageTitle: 'Invitation to Spacent location',
  youHaveBeenInvited: 'You have been invited',
  inviteIAgreeTo: 'I agree to Spacent',
  agreeToRest:
    ', and that Spacent can store my data and contact me regarding my reservation.',
  userTerms: 'User Terms',
  inviteMoreInfoInEmail: `We have sent more detailed information about the reservation to
  your email but here is the gist of it`,
  verifyYourEmail: 'Please verify your email to continue',
  inviterNoInvitesLeft: 'Inviter has no more invites left',
  inviteUnavailable: 'Invite no longer available',
  inviterReservationUnavailable:
    'Sorry, the reservation you were invited to is not available anymore.',
  reservationNotFound: 'Reservation not found.',
  reservationCancelled: 'Reservation cancelled.',
  reservationCancelingDenied: 'You can not cancel this reservation.',
  reservationCancelingFailed: 'Failed to cancel reservation',
  reservationFailed: 'Failed to create reservation',
  missingEmail: 'Missing email.',
  invitationsAllSpent: 'All invitations have been used.',
  duplicateInvitationAccept: 'You have already accepted this invitation.',
  missingInvitation: 'Missing invitation.',
  roomIsFull: 'Room is full.',
  unknownReason: 'Unknown reason.',
  tryAgainLater: 'Please try again later.',

  // statistcs
  statistics: 'Statistics',
  hostStatistics: 'Space statistics',
  impactDashboard: 'Savings calculator',
  lastMonth: 'Last month',
  lastWeek: 'Last week',
  activeUsers: 'Active users',
  noStatisticsData:
    'There are no reservations to display between the selected dates.',
  totalReservations: 'Total reservations',
  totalNetworkReservations: 'Total network reservations',
  usedCapacity: 'Used capacity',
  totalReservationsBySpace: 'Active reservations by space',
  totalReservationsBySpaceExplanation:
    'Amount of reservations for each space that were active in the given time period.',
  totalReservationsByWeekday: 'Reservations by weekday',
  totalReservationsByWeekdayExplanation:
    'Amount of reservations for each weekday that were active in the given time period.',
  weekday: 'Weekday',
  activeReservationsByDuration: 'Reservations by duration',
  space: 'Space',
  activeReservationDurationExplanation:
    'Distribution of reservations duration that were active in the given time period.',
  averageRating: 'Average rating',
  createdReservations: 'Created reservations',
  cancelledReservations: 'Cancelled reservations',
  totalUsers: 'Total users:',
  ofWhich: 'of which',
  wereActive: 'were active',
  duration: 'Duration',
  dayAbbreviation: 'd',
  hourAbbreviation: 'h',
  print: 'Print',
  statisticsLocationFilterDescription:
    'Get statistics from a specific location.',

  // Impact parameters
  numberOfEmployees: 'Number of employees',
  mobileWorkers: 'Mobile workers',
  mobileWorkersPercent: 'Mobile workers %',
  daysDescription: 'days',
  daysInFlexLocations: 'Days in flex locations',
  inFlexLocations: 'In flex locations',
  daysWorkingFromHome: 'Days working from home',
  workingFromHome: 'Working from home',
  daysAtFixedOffice: 'Days at fixed office',
  atFixedOffice: 'At fixed office',
  modeledFromNumberOfEmployees: 'Modeled from the number of employees',
  exactFixedOfficeSize: 'Exact fixed office size (m²)',
  areaPerEmployee: 'Area per employee',
  useDetailedModel: 'Use detailed model',
  areaPerPerson: 'Area per person (m²)',
  fixedOfficeCostPerM2: 'Fixed location cost',
  impactParametersSaved: 'Impact parameters saved.',
  impactParametersSaveError:
    'Uh oh 😭. There was an issue saving the impact parameters.',

  // Impact dashboard
  impactDashboardTitle: 'Hybrid workplace impact',
  impactIntroduction:
    'Simulate how your own organization and office affect costs, CO₂ emissions and your employees’ commuting times. By filling in information about your own office and organization, you’re on your way to find the best possible workplace model for your company.',
  startImpactCalculation: 'Start',
  edit: 'Edit',
  totalImpact: 'Total impact',
  potentialForFixedLocationDownsizing: 'Potential for fixed office downsizing',
  potentialForFixedLocationDownsizingTooltip:
    'How many square meters less you need fixed office space compared to your current office to make your workplace optimal',
  totalCostSavings: 'Total cost savings',
  totalCostSavingsTooltip:
    'How much money your company can potentially save when moving from one fixed office to working remotely anywhere',
  occupancyCostSavings: 'Occupancy cost savings',
  occupancyCostSavingsTooltip:
    'How much your organisation’s monetary savings are per a full-time equivalent employee',
  co2Saved: 'Total CO₂ saved',
  co2SavedTooltip:
    'CO₂ savings gained from reducing office size and replacing the use with Spacent network spaces',
  co2SavedDescription: 'kilograms per year',
  fixedOffice: 'Fixed office only',
  fixedOfficeSize: 'Fixed office size',
  fixedOfficeSizeTooltip: 'Square meters of your fixed office',
  squareMeters: 'm²',
  fixedOfficeCost: 'Fixed office cost',
  fixedOfficeCostTooltip:
    'Total cost of your fixed office space with your given cost per square meter',
  hybridWorkplace: 'Fixed office + work from home + flex spaces',
  reducedFixedOfficeSize: 'Reduced fixed office size',
  reducedFixedOfficeSizeTooltip: 'Square meters of your fixed office',
  reducedFixedOfficeCost: 'Reduced fixed office cost',
  reducedFixedOfficeCostTooltip:
    'Total cost of your fixed office space with your given cost per square meter',
  requiredFlexUse: 'Required flex space use',
  requiredFlexUseTooltip: 'Hours worked from flex locations in a month',
  requiredFlexUseDescription: 'hours per month',
  flexUseCost: 'Flex use cost',
  flexUseCostTooltip: 'Cost of working from flex locations in a month',
  totalHybridWorkplaceCosts: 'Total hybrid workplace costs',
  totalHybridWorkplaceCostsTooltip:
    'Total cost for fixed office and flex workspaces',

  // commutes
  commutesTitle: 'Commuting impact',
  commutesIntroduction: 'Hello I am intro',
  commutesSomethingTitle: 'Something',
  commutesSomethingTooltip: 'Hello I am placeholder',
  commuteSomethingUnit: 'unit',
  commutesSubtitle: 'Commuting summary',
  car: 'Car',
  publicTransport: 'Public transport',
  walkOrBike: 'Walking or Bike',
  percent: 'Percent',
  commuteMethodTitle: 'Commuting method',
  commuteMethodLegend: 'Method',
  commuteTotalImpactTitle: 'Total savings from less commuting',
  commuteTotalTimeTitle: 'Total time saved',
  commuteTotalTimeTooltip:
    'Total time saved from commuting over the selected time period',
  commuteAvgTimeTitle: 'Average time saved',
  commuteAvgTimeTooltip:
    'Average time saved per employee from commuting over the selected time period',
  commuteMinutesUnit: 'minutes',
  commuteDaysUnit: 'days',
  commuteTotalDistanceTitle: 'Total distance saved',
  commuteTotalDistanceTooltip:
    'Total distance saved from commuting over the selected time period',
  commuteAvgDistanceTitle: 'Average distance saved',
  commuteAvgDistanceTooltip:
    'Average distance saved per employee from commuting over the selected time period',
  commuteKmUnit: 'km',
  commuteTotalCo2Title: 'Total CO₂ saved',
  commuteTotalCo2Tooltip:
    'Total CO₂ saved from commuting over the selected time period',
  commuteAvgCo2Title: 'Average CO₂ saved',
  commuteAvgCo2Tooltip:
    'Average CO₂ saved per employee from commuting over the selected time period',
  commuteKgUnit: 'kg',
  commuteUsersTitle:
    'This is how the savings above have been calculated — first about users',
  commuteUsersCounts: 'Users whose commutes were used',
  commuteUsersInputHomeAddress: 'Address given',
  commuteUsersInferredHomeAddress: 'Location inferred',
  commuteUsersUnit: 'users',
  commuteResCountsTitle: 'Reservations during the time period',
  commuteResNotInferredName: 'Commute not inferred',
  commuteResInferredName: 'Commute Inferred',
  commuteResUnit: 'reservations',
  commuteResLabel: 'Reservations',
  commuteMethodsGivenTitle: 'Commuting method given',
  commuteMethodInputTitle: 'Method given',
  commuteMethodInferredTitle: 'Method extrapolated',
  commuteDefinitionTitle: 'Commutes',
  commuteMethodEstimates: 'Background information for estimates',
  commuteEmployeeImpactTitle: 'Impact from less commuting per employee',
  commuteLastTimeRange: 'For the last',
  commuteLastTimeRangeShort: 'The last',
  commutesSpacentLocationsTitle: 'Spacent locations',
  commutesFixedOfficesTitle: 'Fixed offices',
  commutesNotAvailable: 'No commuting data available',

  // user statistics
  statisticsMaintenance:
    "We're currently updating the statistics data. Please check back later!",
  occupierStatistics: 'User statistics',
  occupierStatisticsTitle: 'User statistics',
  useAnotherTimezone: 'Use another timezone',
  comparingToPrevious: 'Comparing to previous',
  comparingToPreviousShort: 'Previous',
  compareYear: 'year',
  compareYears: 'years',
  compareMonth: 'month',
  compareMonths: 'months',
  compareWeek: 'week',
  compareWeeks: 'weeks',
  compareDay: 'day',
  compareDays: 'days',
  hoursUnit: 'h',
  starsUnit: '/5',
  userStatistics: 'User statistics',
  usersWhoMadeReservations: 'Users Who Made Reservations',
  reserversFromOwnOffices: 'Own office users',
  reserversFromSpacent: 'Spacent network users',
  averageUserRating: 'Average of ratings',
  useBySpaceType: 'Own office use vs. Spacent network use',
  ownSpaces: 'Own spaces',
  spacentNetwork: 'Spacent network',
  networkUserBySpaceType: 'Spacent network use by space type',
  hotDeskReservations: 'Hot desk',
  privateOfficeReservations: 'Private office',
  teamRoomReservations: 'Team room',
  meetingRoomReservations: 'Meeting room',
  usageStatistics: 'Usage statistics',
  networkUsageStatistics: 'Spacent network usage statistics',
  networkStatisticsBySpaceType: 'Spacent network statistics by space type',
  networkHotDeskReservationsByDay: 'Network hot desk reservations by day',
  networkMeetingRoomHoursByDay: 'Network meeting room reservations by day',
  networkPrivateOfficeRoomHoursByDay:
    'Network private office reservations by day',
  networkTeamRoomHoursByDay: 'Network team room reservations by day',
  ownSpacesStatisticsBySpaceType: 'Own spaces statistics by space type',
  ownSpacesHotDeskReservationsByDay: 'Own hot desk reservations by day',
  ownSpacesMeetingRoomHoursByDay: 'Own meeting room reservations by day',
  ownSpacesPrivateOfficeRoomHoursByDay:
    'Own private office reservations by day',
  ownSpacesTeamRoomHoursByDay: 'Own team room reservations by day',
  dailyPeakOfNetworkReservations: 'Daily peak of reservations',
  avgDurationOfNetworkReservations: 'Average duration of reservations',
  avgDurationOfNetworkMeetingRoomReservations:
    'Average duration of meeting room reservations',
  avgDurationOfOwnMeetingRoomReservations:
    'Average duration of meeting room reservations',
  dailyPeakOfHotDeskReservations: 'Daily peak of hot desk reservations',
  dailyPeakOfMeetingRoomReservations: 'Daily peak of meeting room reservations',
  avgDurationOfMeetingRoomReservations:
    'Average duration of meeting room reservations',
  totalDurationOfMeetingRoomReservations:
    'Meeting room reservation hours total',
  date: 'Date',
  hoursLegend: 'Hours',
  hotDeskReservationsByDay: 'Hot desk reservations by day',
  meetingRoomUseByDay: 'Meeting room use by day',
  networkReservationsByDay: 'Network reservations by day',
  topNetworkLocations: 'Top used Spacent network locations',
  topRatedNetworkLocations: 'Top rated Spacent network locations',
  totalOwnSpaceReservationsBySpace: 'Total own space reservations by space',
  noData: 'No reservations',
  uniqueVisitors: 'Unique visitors',
  showAll: 'Show all',
  showLess: 'Show less',
  givenRatings: 'Number of ratings given',
  reservationsCount: 'Reservations',
  selectedTimeFrame: 'Selected time frame',
  volumeBasedUsageStatsTitle: 'Volume-Based Pass usage',

  // Push notifications
  pnReservationStartTitle: 'Your reservation is starting soon',
  pnReservationStartBody:
    'See how to get into the space or cancel the reservation before its too late!',

  // Payment method webview
  addCardHeader: 'Add a new card',

  // My Location translations
  organizationLocations: 'Organization locations',
  colleaguesReservations: "Colleagues' reservations",
  colleaguesReservationsPlaceholder:
    'Reservations shared by your colleagues will be shown here',
  userLastVisitedLocations: 'Last visited',

  // usergroups -- app
  userGroupsOfMyOrganization: 'Groups of my organization',
  userGroupsOfMyOwn: 'My own groups',
  userGroupsOfOtherUsers: 'Groups I am a member of',

  // BetaTag
  betaTagTooltip:
    'This feature is currently in beta. This means that we think that it is helpful and working as intended, but we would really love to get your feedback on it!',

  // App update descriptions for application (can be markdown)
  appRecommendUpdateDescriptions: '',
  appRequiredUpdateDescription: '',

  // Mui-Table
  muiTableRowsPerPage: 'Rows per page:',

  // HelpTooltip
  hideSpaceFromCalendarHelp:
    'Reason for hiding so that you and others remember why the space is hidden ;)',

  webMembershipDefaultAllUsersUsergroupName: 'Only pay-per-use',
  webMembershipBasicMembershipUsergroupName: 'Basic',
  webBusinessMembershipUsergroupName: 'Business',
  webBusinessPlusMembershipUsergroupName: 'Business+',

  // products
  spaces_quotas: 'Spaces Quotas',
  coworking_pass: 'Coworking Pass',

  ...enCountryCodeTranslations,

  // Country code overrides
  CA: 'Canada',
  CN: 'China',
  FI: 'Finland',
  GR: 'Greece',
  SE: 'Sweden',
  US: 'USA',
  AT: 'Austria',
  EE: 'Estonia',
  ES: 'Spain',
  LT: 'Lithuania',
  LV: 'Latvia',
  NO: 'Norway',
  DK: 'Denmark',
  RO: 'Romania',
  CH: 'Switzerland',
  NL: 'Netherlands',
  DE: 'Germany',
  SG: 'Singapore',
  GB: 'United Kingdom',
  BE: 'Belgium',
  PT: 'Portugal',
  PL: 'Poland',
  CZ: 'Czech Republic',

  EUR: 'Euro (€)',
  SEK: 'Swedish krone (kr)',
  NOK: 'Norwegian krone (kr)',
  DKK: 'Danish krone (kr)',
  RON: 'Romanian leu (lei)',
  CHF: 'Swiss franc (fr)',
  PLN: 'Polish zloty (zł)',
  GBP: 'British pound (£)',
  CZK: 'Czech koruna (Kč)',
  USD: 'US dollar ($)',
  CAD: 'Canadian dollar (CAN$)',

  // Feature flags
  FEATURE_DISPLAY_PROJECT_SPACES: 'Display private offices in the app',
  FEATURE_COLLEAGUES_VIEW: 'Display colleagues in the app',
  FEATURE_TOP_LEVEL_COLLEAGUES:
    'Display separate colleagues bottom navigation item in the app',
  FEATURE_SEARCH: 'Search bar in the app',
  FEATURE_GUEST_CHECK_IN:
    'Guest check-in link for meeting room calendar events',
  FEATURE_INVITE_A_GUEST: 'Display invite a guest button in the app',
  FEATURE_QUICK_FEEDBACK: 'Display quick feedback button(s) in the app',
  FEATURE_RESERVATION_SUGGESTIONS: 'Display reservation suggestions in the app',
  FEATURE_TAG_FAVORITES: 'Tag spaces as favorites in the app',
  FEATURE_ORGUSER_CREDITCARD:
    'Allow user in an organization to manage their own credit card in the app',
  FEATURE_SPACE_NEXT_AVAILABLE_SLOT:
    'Show next available slot for a space in the app',
  FEATURE_HOME_ADDRESS: 'Ask users for their home address',
  FEATURE_TEAM_SPACES: 'Allow inviting team members to a team space',
  FEATURE_UPGRADE: 'Show CTA to upgrade membership',
  FEATURE_USER_IMPERSONATING: 'Enables user impersonation in the app',
  FEATURE_RESERVATION_CONFIRMATIONS:
    'Show active and upcoming / unconfirmed reservations in the apps home screen',
}

const validations = {
  required: ({ field }: { field: string }) => `${field} is a required field`,
  email: ({ field }: { field: string }) => `${field} must be a valid email`,
  min: ({ field, min }: { field: string; min: string }) =>
    `${field} must be at least ${min} characters long`,
  max: ({ field, max }: { field: string; max: string }) =>
    `${field} must be at most ${max} characters long`,
  phoneFormat: ({ field }: { field: string }) =>
    `${field} must be only numbers starting with a plus, for example: +123456789`,
  domainSyntax: ({ field }: { field: string }) =>
    `${field} must consist of words separated by dots, like "example.com".`,
  domainAvailability: ({ field }: { field: string }) =>
    `${field} is used by other organizations or is not used for emails.`,
  domains: ({ field }: { field: string }) =>
    `${field} must consist of words separated by dots, like "example.com".`,
  businessIdOnly: () => 'Only business id (1234567-8, 1234567890)',
  minThreePhotos: () => 'At least three photos are required',
  mustBeUrl: ({ field }: { field: string }) => `${field} must be a valid URL`,
}

const formatters = {
  reservationNotification: (props: {
    roomName: string
    locationAddress: string
    startTime: string
    endTime: string
  }) =>
    `A new reservation has been made for ${props.roomName} at ${props.locationAddress} from ${props.startTime} to ${props.endTime}.`,

  reservationConcernNotificationSubject: (props: {
    startTime: string
    locationAddress?: string
    roomName: string
  }) =>
    `Concerning your reservation ${props.startTime} ${
      props.locationAddress
        ? `at ${props.locationAddress}`
        : `in ${props.roomName}`
    }`,

  cancellationNotification: (props: {
    roomName: string
    locationAddress: string
    startTime: string
    endTime: string
  }) =>
    `Reservation of ${props.roomName} at ${props.locationAddress} from ${props.startTime} to ${props.endTime} has been cancelled.`,

  colleaguesReservationsDescription: (props: {
    colleagues: number
    locations: number
    spaces: number
    when: string
  }) => {
    const plural = (n: number, unit: string, multiple: string) =>
      `${n} ${n === 1 ? unit : multiple}`

    return props.colleagues > 0
      ? [
          'You have',
          plural(props.colleagues, 'colleague', 'colleagues'),
          'working in',
          plural(props.locations, 'location', 'different locations'),
          'using',
          plural(props.spaces, 'space', 'different spaces'),
          `${props.when}.`,
        ].join(' ')
      : 'Your colleagues have not made any reservations. Be the first one!'
  },

  corporateSubscriptionMembershipFor: (props: { users: number }) =>
    `Business monthly pass for up to ${props.users} users`,

  corporateMembershipsUtilizationExplained: (props: {
    reservationsUsed: any
    reservationsProvided: any
    memberships: any
  }) => [
    props.reservationsUsed,
    'accesses used out of total',
    props.reservationsProvided,
    'provided for',
    props.memberships,
    'memberships.',
  ],

  membershipUnitPriceTotalMonthly: (props: {
    unit: string
    count: any
    price: any
    discount: any
    total: any
  }) => [
    props.count,
    props.unit,
    props.price,
    'each;',
    props.discount,
    'total',
    props.total,
    'monthly.',
  ],

  dedicatedMembershipUtilizationExplained: (props: {
    reservationCount: any
    memberCount: any
  }) => [
    props.reservationCount,
    'reservations by',
    props.memberCount,
    'members this month.',
  ],

  moneyTaxFormat: (props: {
    amount: string
    taxName: string
    taxRate: number
  }) => `${props.amount} + ${props.taxRate * 100}% ${props.taxName}`,

  maximumReservationLengthInHours: (props: { hours: number }) =>
    `Longest allowed reservation is ${props.hours} hours`,

  smsVerificationFormat: (props: { code: string }) =>
    `Please enter ${props.code} as your Spacent verification code.`,

  smsCancelledFormat: (props: { dateTime: string }) =>
    `Your booking was cancelled. \nWe're really sorry but your Spacent booking starting on ${props.dateTime} was cancelled.`,

  pnReservationCancelledTitle: 'Your reservation was cancelled',
  pnReservationCancelledBody: (props: { dateTime: string }) =>
    `Reservation on ${props.dateTime} has been cancelled by the host. Open Spacent app to make a new reservation.`,

  pnTeamRoomReservationTitle: 'New team room reservation',
  pnTeamRoomReservationBody: (props: {
    locationName: string
    cityName: string
    dateTime: string
  }) =>
    `You’ve been added to a team room reservation at ${props.locationName}, ${props.cityName} on ${props.dateTime} Open Spacent app to see the details.`,

  reservationIsOpenOn: (props: AllowedReservationTimes) =>
    [
      'Reservation is open only on:',
      ...openTimesForDays(props, timeFormats),
    ].join('\n  '),

  priceRange: (props: { firstPrice?: number; lastPrice?: number }) => {
    if (!props.firstPrice && !props.lastPrice) {
      return ''
    }

    if (!props.lastPrice || props.firstPrice === props.lastPrice) {
      return `${props.firstPrice}`
    }

    return `${props.firstPrice} - ${props.lastPrice}`
  },

  shortHandPricingByUnit: (props: {
    pricePerUnit: number
    currency: CurrencyCode
    unit: TieredUnit
  }) =>
    `${rounded(props.pricePerUnit)} ${
      constantStrings[props.currency]
    } / ${formatters.tieredUnitToShorthand({ unit: props.unit })}`,

  shorthandPricingFree: () => 'Free',

  shorthandPricingHourly: (props: {
    pricePerHour: number
    currency: CurrencyCode
  }) => `${props.pricePerHour} ${constantStrings[props.currency]} / hour`,

  shorthandPricingTieredByUnit: (props: {
    lowestPrice: number
    highestPrice: number
    unit: TieredUnit
    currency: CurrencyCode
  }) =>
    `${props.lowestPrice} ${constantStrings[props.currency]} - ${
      props.highestPrice
    } ${constantStrings[props.currency]} / ${formatters.tieredUnitToShorthand({
      unit: props.unit,
    })}`,

  shorthandPricingTieredByLength: (props: {
    lowestPrice: number
    highestPrice: number
    currency: CurrencyCode
  }) =>
    `From ${props.lowestPrice} ${constantStrings[props.currency]} to ${
      props.highestPrice
    } ${constantStrings[props.currency]}`,

  shorthandPricingByVolume: (props: {
    lowestVolumePrice: [number, TieredUnit]
    highestVolumePrice?: [number, TieredUnit]
    currency: CurrencyCode
  }) => {
    return `${props.lowestVolumePrice[0]} ${
      constantStrings[props.currency]
    } / ${formatters.tieredUnitToShorthand({
      unit: props.lowestVolumePrice[1],
    })} ${
      props.highestVolumePrice
        ? ` - ${props.highestVolumePrice[0]} ${
            constantStrings[props.currency]
          } / ${formatters.tieredUnitToShorthand({
            unit: props.highestVolumePrice[1],
          })}`
        : ''
    }`
  },

  effectivePublishingStatusExplanation: (
    actualPublishingStatus: PublishingStatus,
    effectivePublishingStatus: PublishingStatus,
  ) =>
    `While you have set the wanted visibility to ${constantStrings[actualPublishingStatus]}, the effective visibility is ${constantStrings[effectivePublishingStatus]} due to the selected ${constantStrings.offerPricingAndAvailability} settings.`,

  tieredUnitToShorthand: (props: { unit: TieredUnit }) => {
    const shorthand: Record<TieredUnit, string> = {
      hours: 'hour',
      months: 'month',
      days: 'day',
      weeks: 'week',
    }

    return shorthand[props.unit]
  },

  emailReservedProjectSpace: (props: { spaceName: string }) =>
    `You have booked the ${props.spaceName} private office!`,

  projectSpaceNotification: (props: {
    roomName: string
    startTime: string
    endTime: string
  }) =>
    `Your reservation for ${props.roomName} is active from ${props.startTime} to ${props.endTime}.`,

  capacityFullMessage: (props: { overlappingReservations: ReservedSlot[] }) =>
    [
      'Space is already reserved on these times:',
      ...props.overlappingReservations.map(
        (r) =>
          `${moment(r.start).format(
            timeFormats.momentDateTimeFormat,
          )} - ${moment(r.end).format(timeFormats.momentTimeFullFormat)}`,
      ),
    ].join('\n'),

  tieredPricingLimitsReservationLength: (props: { hours: number }) =>
    `This also limits the maximum reservation time to ${props.hours} hours.`,

  usergroupMembersCount: (props: { count: number | undefined }) =>
    `${props.count || 'No'} members`,

  confirmDetachLobbyUsergroup: (props: { name: string }) =>
    `Stop members of usergroup "${props.name}" from managing reservations here?`,

  lobbyUsergroupNameDefault: (props: { name: string | undefined }) =>
    props.name ? `Lobby of ${props.name}` : 'Lobby',

  longhandSpaceType: (spaceType: SpaceType) =>
    ({
      meeting: 'Meeting room',
      desks: 'Desk',
      project: 'Private room',
      other: 'Other',
      team: 'Team room',
    }[spaceType] ?? ''),

  shorthandSpaceType: (props: { type: SpaceType }) =>
    ({
      meeting: 'Meeting',
      desks: 'Desks',
      project: 'Private',
      other: 'Other',
      team: 'Team',
    }[props.type] ?? ''),

  appSpaceType: (spaceType: SpaceType) =>
    ({
      meeting: 'Meeting rooms',
      desks: 'Working desks',
      project: 'Private rooms',
      other: 'Other',
      team: 'Team rooms',
    }[spaceType]),

  volumeMembershipSpaceTypeReservationUnit: (spaceType: SpaceType) =>
    ({
      meeting: 'per hour',
      desks: 'per desk per day',
      project: 'per room per day',
      other: 'per reservation',
      team: 'per desk per reservation',
    }[spaceType]),

  someReservations: (props: { count: number }) =>
    props.count === 1 ? `1 reservation` : `${props.count} reservations`,

  spaceToHideContainsSomeReservations: (props: {
    reservations: number
    spaces: number
  }) => {
    const reservations =
      props.reservations > 1
        ? `${props.reservations} reservations already exist`
        : 'A reservation already exists'
    const spaces = props.spaces > 1 ? `${props.spaces} spaces` : `one space`

    return `${reservations} for ${spaces} during that period. These reservations need to be cancelled before you can block reservations.`
  },

  viewSomeReservations: (props: { count: number }): string =>
    props.count === 1 ? `View reservation` : `View reservations`,

  cancelSomeReservations: (props: { count: number }) =>
    props.count === 1
      ? `Cancel reservation`
      : `Cancel ${props.count} reservations.`,

  cancelledSomeReservations: (props: { count: number }) =>
    props.count > 1
      ? `Cancelled ${props.count} reservations.`
      : `Cancelled reservation.`,

  failedToCancelSomeReservations: (props: { count: number }) =>
    props.count > 1
      ? `Failed to cancel ${props.count} reservations.`
      : `Failed to cancel the reservation.`,

  discountLimited: (props: { months: number }) =>
    props.months > 1
      ? `for ${props.months} first months`
      : `for the first month`,

  afterDiscountLimited: (props: { months: number }) =>
    props.months > 1
      ? `after ${props.months} first months`
      : `after the first month`,

  inviteAcceptFailedForInviterNoInvitesLeft: (props: { invitee: string }) =>
    `${props.invitee} could not accept your invite on Spacent because you have no invitations left.`,

  inviteAcceptedForInviter: (props: {
    invitee: string
    where: string
    when: string
  }) =>
    `${props.invitee} accepted your Spacent invitation to ${props.where} on ${props.when}.`,

  inviteCancelledForInviter: (props: {
    invitee?: string
    where: string
    when: string
  }) =>
    `${props.invitee} cancelled their participation at ${props.where} on ${props.when}.`,

  calendarCapacityExplanation: (props: {
    usedCapacity: number
    totalCapacity: number
  }) =>
    `Used: ${props.usedCapacity}, Total: ${
      props.totalCapacity
    }, Available: ${Math.max(props.totalCapacity - props.usedCapacity, 0)}`,

  potentialForFixedLocationDownsizingDescription: (props: {
    percent: number
  }) => `m² (${props.percent} %)`,

  totalCostSavingsDescription: (props: {
    currencySymbol: string
    percent: number
  }) => `${props.currencySymbol} / month (${props.percent} %)`,

  occupancyCostSavingsDescription: (props: { currencySymbol: string }) =>
    `${props.currencySymbol} / month / FTE`,

  currencyPerMonth: (props: { currencySymbol: string }) =>
    `${props.currencySymbol} / month`,

  fixedOfficeCostPerM2Description: (props: { currencySymbol: string }) =>
    `${props.currencySymbol} / m² / month`,

  flexCostPerHour: (props: { currencySymbol: string }) =>
    `Flex locations usage cost (${props.currencySymbol} / h)`,

  registeredUsersOn: (props: { date: string }) =>
    `Registered users on ${props.date.replace(/ /g, '\xa0')}`,

  // push notifications
  pushNotificationBody: (props: { cancellable: boolean }): string =>
    props.cancellable
      ? "Check instructions for arrival before the start of your reservation or cancel it before it's too late!"
      : 'Check instructions for arrival before the start of your reservation.',

  pnBusinessPassTitle: 'Unlock all workspaces!',
  pnBusinessPassBody:
    'Click here to upgrade to Business trial, and get access to a wider selection of spaces.',
  pnBusinessPassActionPrefilledText: (props: { email: string }) =>
    `I’m interested in unlocking all locations and trying the Business Plan! Please contact me (${props.email}) soon to get started.`,

  pnReservationConfirmationReminderTitle: 'Confirm your reservation!',
  pnReservationConfirmationReminderBody: `Please remember to confirm or cancel your upcoming reservation in the app.`,

  customNetworkCount: (props: { count: number }): string =>
    `Your custom network consists currently of ${props.count} ${
      props.count === 1 ? 'location' : 'locations'
    }.`,

  heardFromSource: (heardFromSource: HeardFromSource): string => {
    const sources: Record<HeardFromSource, string> = {
      colleagues: 'Colleagues',
      other: 'Other',
      company_communication_channel: 'Company communication channel',
      company_event: 'Company event',
      email_messaging: 'Email',
      other_social_media: 'Social media (e.g. LinkedIn, Instagram)',
      prefer_not_to_say: 'Prefer not to say',
      search_engine: 'Google (or other search engine)',
      social_media: 'Social media',
      word_of_mouth: 'Friend/colleague',
    }

    return sources[heardFromSource] || heardFromSource
  },

  affectsXOtherSpaces(otherSpaceIdCount: number): string {
    return `Are you sure you want to edit pricing that affects ⚠️${otherSpaceIdCount} other space${
      otherSpaceIdCount > 1 ? 's' : ''
    }?`
  },

  cityInCountry(country: string): string {
    return `City in ${country}`
  },

  uploadError(error: string): string {
    const errorMap: Record<string, string> = {
      'file-invalid-type': 'Not supported filetype',
      'file-too-large': 'File is too large. Max size is 10MB',
    }

    return errorMap[error] || 'Not supported'
  },

  sellableExplanation: (props: {
    key: SpacentSellableType
    price?: number
    currency?: string
  }): Record<string, string | boolean>[] => {
    switch (props.key) {
      case 'coworkingPass5persons':
        return [
          { text: 'Business monthly pass, ' },
          { text: 'fixed price', strong: true },
          {
            text: ` for 1–5 users${
              props.price && props.currency
                ? ` (${props.price} ${props.currency})`
                : ''
            }`,
          },
        ]

      case 'coworkingPass10persons':
        return [
          { text: 'Business monthly pass, ' },
          { text: 'fixed price', strong: true },
          {
            text: ` for 6–10 users${
              props.price && props.currency
                ? ` (${props.price} ${props.currency})`
                : ''
            }`,
          },
        ]

      case 'volumeBasedReservations':
        return [
          { text: 'Pay-per-use ', strong: true },
          { text: 'Business account, unlimited users' },
        ]

      default:
        return [{ text: spacentSellableNames[props.key] }]
    }
  },
}

const sharedMembershipPricePointTitles: Record<
  number,
  { [businessRegionId: string]: string }
> = {
  2: {
    minUpTo5memberships: '1-5 users',
    maxUpTo10memberships: '6-10 users',
    volumeBasedReservations: 'Volume based',
  },
  3: {
    minUpTo5memberships: '1-5 users',
    maxUpTo10memberships: '6-10 users',
    volumeBasedReservations: 'Volume based',
  },
  4: {
    twoToFiveMemberships: '2-5 users',
    maxUpTo10memberships: '6-10 users',
    volumeBasedReservations: 'Volume based',
  },
}

// full translation
export const en = {
  // TODO: Remove these instead of just spreading them in order to avoid conflicts and overlaps in the future.
  ...formatters,
  ...constantStrings,
  validations,
  timeFormats,
  timeFormatters: generateTimeFormatters(timeFormats),
  formatters,
  constantStrings,
  sharedMembershipPricePointTitles,
}
